import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames/bind'
import {getStatistics} from 'redux/actions/statistics'
import 'chart.js/auto' // register all used elements of Chart.js (Doughnut below)
import {Doughnut} from 'react-chartjs-2'
import styles from './statisticsPage.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import Table from 'component/Table'
import {TABLET_SCREEN_WIDTH} from 'helper/constants'
import ruka from 'asset/img/ruka.png'
import AverageRentTable from 'component/AverageRentTable'
import NumberFormat from 'react-number-format'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const StatisticsPage = (props) => {
  const {statistics, getStatistics} = props
  const [activeStat, setActiveStat] = useState(0)
  const maxActiveStat = 3

  const mobileView = window.innerWidth < TABLET_SCREEN_WIDTH

  useEffect(() => {
    getStatistics()
  }, [getStatistics])

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  const statTitle = [
    'Průměrné nájemné',
    'Průměrná délka nájmu',
    'Poměr dispozic bytů',
    'Průměrná spotřeba komodit',
  ]

  const prepareConditionObject = (condition) => {
    const novostavba = condition.conditions.filter(
      (cond) => cond.condition_name === 'Novostavba'
    )[0]?.avg_rent_price
    const ostatni = condition.conditions.filter((cond) => cond.condition_name === 'Ostatní')[0]
      ?.avg_rent_price

    const preparedObject = {
      disposition_name: condition.disposition_name,
      novostavba: novostavba ? novostavba : '----',
      ostatni: ostatni ? ostatni : '----',
    }

    return preparedObject
  }

  const dataAvgRentPriceWithConditions = statistics.by_condition?.map((condition) =>
    prepareConditionObject(condition)
  )

  const dataAvgRentPrice = statistics.by_disposition?.map((item) => {
    return {disposition_name: item.disposition_name, avg_rent_price: item.avg_rent_price}
  })

  function mergeArrays(a, b, prop) {
    const resObj = []
    a.forEach((aitem) => {
      if (b.some((bitem) => aitem[prop] === bitem[prop])) {
        resObj.push({...aitem, ...b.find((bitem) => aitem[prop] === bitem[prop])})
      }
      // THIS ELSE BRANCH ADDS FULL JOIN FOR ARRAYS
      // else {
      //   resObj.push({...aitem, novostavba: '----', ostatni: '----'})
      // }
    })
    return resObj
  }

  const chartColors =
    //['#00abc4', '#43e49e', '#eab22d', '#f14d21', '#7D2E68', '#232ED1', '#EAFC5C']
    [
      '#00abc4',
      '#00b4b8',
      '#00baa0',
      '#32be7f',
      '#6bc059',
      '#9dbd31',
      '#ceb500',
      '#ffa600',
      '#ff3050',
      '#fb388a',
      '#e057bc',
      '#b375e0',
      '#778cf0',
      '#2c9ced',
      '#00a6dc',
    ]
  const legendOptions = {legend: {position: mobileView ? 'top' : 'left'}}
  const toottipOptionsCallbacks = {
    title: function (tooltipItem, data) {
      return data['labels'][tooltipItem[0]['index']]
    },
  }
  const avgRentLengthChartOptions = {
    ...legendOptions,
    tooltips: {
      callbacks: {
        ...toottipOptionsCallbacks,
        label: function (tooltipItem, data) {
          return ` ${data['datasets'][0]['data'][tooltipItem['index']]} měsíců`
        },
      },
    },
  }
  const dispositionsChartOptions = {
    ...legendOptions,
    tooltips: {
      callbacks: {
        ...toottipOptionsCallbacks,
        label: function (tooltipItem, data) {
          return ` ${data['datasets'][0]['data'][tooltipItem['index']]} %`
        },
      },
    },
  }

  const avgRentLengthSorted = statistics.by_disposition?.sort(
    (a, b) => b.avg_rent_length - a.avg_rent_length
  )

  const dataAvgRentLength = {
    datasets: [
      {
        data: avgRentLengthSorted?.map((item) => item.avg_rent_length),
        backgroundColor: chartColors,
      },
    ],
    labels: avgRentLengthSorted?.map((item) => item.disposition_name),
  }

  const dispositionsSorted = statistics.by_disposition
    ?.sort((a, b) => b.disposition_percentage - a.disposition_percentage)
    .filter((a) => a.disposition_percentage >= 0.005)

  const dataDispositions = {
    datasets: [
      {
        data: dispositionsSorted?.map(
          // Round to 2 decimal places and show as percentage of 100
          (item) => Math.round(item.disposition_percentage * 10000) / 100
        ),
        backgroundColor: chartColors,
      },
    ],
    labels: dispositionsSorted?.map((item) => item.disposition_name),
  }

  const fieldsByPerson = [
    {key: 'comodity', title: 'Komodita', style: styles.td},
    {
      key: 'value',
      title: 'Průměrná spotřeba na osobu',
      style: styles.tdValues,
      styleTh: styles.thValues,
    },
  ]

  const dataByPerson = [
    {
      comodity: 'Studená voda - objem',
      value:
        statistics.by_person?.cold_water_m3 &&
        `${statistics.by_person?.cold_water_m3?.toLocaleString()} m³`,
    },
    {
      comodity: 'Studená voda - cena',
      value: (
        <NumberFormat
          thousandSeparator={' '}
          displayType="text"
          suffix={' Kč'}
          value={statistics.by_person?.cold_water_price?.toLocaleString()}
        />
      ),
    },
    {
      comodity: 'Teplá voda - objem',
      value:
        statistics.by_person?.hot_water_m3 &&
        `${statistics.by_person?.hot_water_m3?.toLocaleString()} m³`,
    },
    {
      comodity: 'Teplá voda - cena',
      value: (
        <NumberFormat
          thousandSeparator={' '}
          displayType="text"
          suffix={' Kč'}
          value={statistics.by_person?.hot_water_price?.toLocaleString()}
        />
      ),
    },
  ].map((item, id) => {
    return {...item, id}
  })

  return (
    <div className={styles.StatisticsPage}>
      <div className={styles.topPartBackground}>
        <div className={styles.topPart}>Statistiky</div>
        <div className={styles.topPartText}>
          Uvedené statistiky vychází z generalizovaných dat získaných ze systému spol. VÁŠ DOMOVNÍK
          s.r.o., pro závěry v konkrétní věci je vždy nezbytné zohlednit i prameny další a specifika
          konkrétní dané věci.
        </div>
      </div>
      <div className={styles.navigator}>
        <div
          onClick={() => setActiveStat((prev) => (prev === 0 ? prev : prev - 1))}
          className={classNames(fi('flaticon-left-arrow'), cx('arrow'), {
            [cx('arrowDisabled')]: activeStat === 0,
          })}
        />
        <div className={styles.navigationText}>{statTitle[activeStat]}</div>
        <div
          onClick={() => setActiveStat((prev) => (prev === maxActiveStat ? prev : prev + 1))}
          className={classNames(fi('flaticon-right-arrow'), cx('arrow'), {
            [cx('arrowDisabled')]: activeStat === maxActiveStat,
          })}
        />
      </div>
      {activeStat === 0 && (
        <AverageRentTable
          data={
            statistics.by_disposition &&
            mergeArrays(dataAvgRentPrice, dataAvgRentPriceWithConditions, 'disposition_name')
          }
        />
      )}
      <div className={styles.table}>
        {activeStat === 1 && (
          <Doughnut data={dataAvgRentLength} options={avgRentLengthChartOptions} />
        )}
        {activeStat === 2 && (
          <Doughnut data={dataDispositions} options={dispositionsChartOptions} />
        )}
        {activeStat === 3 && <Table fields={fieldsByPerson} data={dataByPerson} />}
      </div>
      {[1, 2].includes(activeStat) && <img alt={'ruka'} src={ruka} className={styles.ruka} />}
    </div>
  )
}

const mapStateToProps = (state) => ({
  statistics: state.statistics.data,
})

const mapDispatchToProps = {
  getStatistics,
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsPage)
