import React from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import styles from './invoice.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import {download} from 'redux/actions/download'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const DownloadPdf = (props) => {
  const {downloadUrl, download} = props

  return (
    <div
      onClick={() => download(downloadUrl)}
      className={classNames(fi('flaticon-pdf-file'), cx('iconPdf'))}
    >
      {' '}
    </div>
  )
}

DownloadPdf.propTypes = {
  downloadUrl: PropTypes.string,
  download: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  download,
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPdf)
