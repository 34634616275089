import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import {createUserImpersonation, userLoginRefresh} from 'redux/actions/user'

// this is applying styles for the whole document
import styles from './app.module.scss'
import LoginPage from 'page/LoginPage'
import HomePage from 'page/HomePage'
import DetailPage from 'page/DetailPage'
import Layout from './Layout'
import PrivateRoute from 'component/PrivateRoute'
import StatisticsPage from 'page/StatisticsPage'
import PostPage from 'page/PostsPage'

import moment from 'moment'
import 'moment/locale/cs'

const App = (props) => {
  const {userLoginRefresh, createUserImpersonation} = props

  moment.locale('cs')

  useEffect(() => {
    userLoginRefresh()
  }, [userLoginRefresh])

  useEffect(() => {
    const impersonationFromStorage = localStorage.getItem('impersonation')
    if (impersonationFromStorage) {
      try {
        createUserImpersonation(JSON.parse(impersonationFromStorage).id)
      } catch (error) {
        console.error(error)
      }
    }
  }, [createUserImpersonation])

  return (
    <>
      <ToastContainer theme="colored" />
      <Router>
        <Switch>
          <Route path="/login">
            <Layout loginLayout={true}>
              <LoginPage />
            </Layout>
          </Route>
          <PrivateRoute exact path="/" component={<HomePage />} />
          <PrivateRoute path="/spolupracovnici/:id" component={<DetailPage />} />
          <PrivateRoute path="/statistiky" component={<StatisticsPage />} />
          <PrivateRoute path="/zajimavosti" component={<PostPage />} />
          <Route path="/404">
            <Layout>
              <div className={styles.notFound}>Stránka nenalezena.</div>
            </Layout>
          </Route>
          <Redirect to="/404" />
        </Switch>
      </Router>
    </>
  )
}

const mapDispatchToProps = {
  userLoginRefresh,
  createUserImpersonation,
}

export default connect(null, mapDispatchToProps)(App)
