import {toast} from 'react-toastify'
import {ENVIRONMENT, MOCK_API, MOCK_PORT_MAPPING, REACT_APP_BACKEND_URL} from './constants'

export const fireErrorToast = (message, options = {autoClose: 5000}) => {
  return toast.error(message, options)
}
export const fireSuccessToast = (message, options = {autoClose: 5000}) => {
  return toast.success(message, options)
}

export const getServiceUrl = (name) => {
  if (ENVIRONMENT !== 'production' && MOCK_API) {
    return `http://localhost:${MOCK_PORT_MAPPING[name]}`
  }
  return `${REACT_APP_BACKEND_URL}/${name}`
}

const loopThroughErrors = (message, errors) => {
  if (errors?.length !== 0) {
    errors.forEach((err) => {
      fireErrorToast(<>{err?.message || message}</>)
    })
  } else {
    fireErrorToast(message)
  }
}

export const globalApiErrorHandler = (error) => {
  if (error.response) {
    const {status, data} = error.response
    switch (status) {
      case 400:
        data?.errors && loopThroughErrors('Bad request', data.errors)
        return Promise.reject(error.response?.data?.errors)
      case 401: // unauthorized
        fireErrorToast('Unauthorized')
        localStorage.removeItem('logged')
        localStorage.removeItem('impersonation')
        break
      case 403:
        data?.errors && loopThroughErrors('Forbidden', data.errors)
        return Promise.reject(error.response?.data?.errors)
      case 404: // not found
        data?.errors && loopThroughErrors('Not found', data.errors)
        return Promise.reject(error.response?.data?.errors)
      case 422: // unprocessable entity
        return Promise.reject(error.response?.data?.errors)
      case 500:
        fireErrorToast('Server error')
        break
      default:
        break
    }
  }
}

export const mapErrorResponseToForm = (err) => {
  let parsedError = {}
  if (Array.isArray(err)) {
    err.forEach((e) => {
      if (e?.source?.includes('__')) {
        let errorSplit = e.source?.split('__')
        if (parsedError?.hasOwnProperty(errorSplit?.[0])) {
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        } else {
          parsedError[errorSplit[0]] = {}
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        }
      } else {
        parsedError[e.source] = e.message
      }
    })
  }
  return parsedError
}

export const globalLoginErrorHandler = (error) => {
  if (error.response) {
    const {status} = error.response
    if (status === 403) {
      fireErrorToast('Špatný e-mail nebo heslo')
      return
    }
  }
  return Promise.reject(error)
}

export const globalLoginRefreshErrorHandler = (error) => {
  if (error.response) {
    const {status} = error.response
    if (status === 403) {
      fireErrorToast('Přihlášení vypršelo')
      return
    }
  }
  return Promise.reject(error)
}

export const removeTrailingSlash = (url) => {
  if (url.length && url[url.length - 1] === '/') {
    return url.slice(0, -1)
  }
  return url
}

export const rethrow = (middleware) =>
  function (error) {
    middleware()
    throw error
  }

export const getSelectValue = (values, attribute) => {
  return values?.[attribute] && Array.isArray(values[attribute])
    ? values[attribute][0].value
    : values[attribute]
}
